











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LoaderInterval extends Vue {
  /**
   * Props
   */
  @Prop() private time!: number;

  /**
   * Data
   */
  private interval: any | null = null;
  private active: boolean = true;
  private value: number = 0;

  /**
   * Lifecycle hooks
   */
  private created() {
    if (this.time) {
      this.run();
    }
  }

  private beforeDestroy() {
    clearInterval(this.interval);
  }

  /**
   * Methods
   */
  private run() {
    this.interval = setInterval(() => {
      if (this.value >= 100) {
        clearInterval(this.interval);
        this.active = false;
      }

      this.value += Math.round(10 / this.time);
    }, 100);
  }
}
